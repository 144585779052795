<template>
  <div class="wizard__container">
    <div class="mb-5" :style="{ textAlign: 'center', marginTop: '20px' }">
      <h2 class="mb-1 font-bold">Video Creator Wizard</h2>
      <p>Here are your created videos</p>
    </div>

    <section class="bg-white container p-5">
      No Videos created...
    </section>
  </div>
</template>
<script>
export default {
  name: 'WizardList',
  data: () => ({}),
};
</script>
